import React from "react";
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from "../consts";
import { Star } from "lucide-react";
 

const avatars = [
  {
    alt: "User",
    src: "https://images.ctfassets.net/7h2p3b5vvfl2/1VIrIaBBVmYDB37IatLA1B/26ea4b1bc09b67a07cad219f06267413/Screen_Shot_2024-01-29_at_10.16.09_AM.png",
  },
  {
    alt: "User",
    src: "https://images.ctfassets.net/7h2p3b5vvfl2/5nShyvFrXuI6RZlund4QO0/768d33584b969f69255806773ba08c8f/unnamed__1_.png",
  },
  {
    alt: "User",
    src: "https://images.ctfassets.net/7h2p3b5vvfl2/z0pqO0B0kipQvxjE6s2mx/8260f76b86e1b3e41305436feaed306d/unnamed__1_.jpeg",
  },

  {
    alt: "User",
    src: "https://images.ctfassets.net/7h2p3b5vvfl2/Mqi3cxcWbsByo4LepxpXC/72cf38009f1c193b53baa766e453e3ed/415526925_24836861265927710_6087822224521137424_n.jpeg",
  },
  {
    alt: "User",
    // Ideally, load from a statically generated image for better SEO performance (import userImage from "@/public/userImage.png")
    src: "https://images.ctfassets.net/7h2p3b5vvfl2/4QHWBXw1hW0ioIxIeJe7Rz/d58ef3251a015939079d3097b528b019/361607911_10223377372170405_5207651543418563927_n.jpeg",
  }
];

const TestimonialsAvatars = ({ priority = false, className, inline, invert }) => {
  return (
    <div className={`flex ${inline ? 'flex-row' : 'flex-col'} md:flex-row justify-center md:justify-start items-center md:items-start gap-3`}>
      {/* AVATARS */}
      <div className={`-space-x-5 flex `}>
        {avatars.sort( () => .5 - Math.random() ).map((image, i) => (
          i > 4 && inline  ? null :
          <div className={`rounded-full overflow-hidden ${inline ? '  ring-2 ring-white w-6 h-6' : '  ring-2 ring-neutral-900 w-8 h-8'}`} key={i}>
            <img
              src={image.src}
              alt={image.alt}
              priority={priority}
              width={50}
              height={50}
            />
          </div>
        ))}
      </div>

      {/* RATING */}
   {inline ? <div>  <div className={`flex items-center mb-1`}>
                    {new Array(5).fill("").map((_,i) => (
                      <Star fill={"#f28d24"} size={16} stroke={"0"} key={i+1}/>
                    ))}
                  </div>
                  <p className={`font-display text-xs font-normal text-neutral-600 ${invert && 'text-white'}`}>
                  <span itemScope className="rating-desc" itemType="https://schema.org/Product">
          <span itemProp="name">Top Tier Roofing</span>{" "}
          <span itemScope itemProp="aggregateRating" itemType="https://schema.org/AggregateRating">
            rated <span itemProp="ratingValue">{REVIEW_AVG}</span>/5 
            based on <span itemProp="reviewCount">{TOTAL_REVIEW_COUNT}</span> reviews.{" "}
          </span>
        </span>
</p>
                  
                  </div> : 
                  
      <div className="flex flex-col justify-center items-center md:items-start gap-1">
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 text-yellow-500"
              key={i}
            >
              <path
                fillRule="evenodd"
                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                clipRule="evenodd"
              />
            </svg>
          ))}
        </div>

        <div className={`text-base ${className}`}>
 
        <span itemScope className="rating-desc" itemType="https://schema.org/Product">
          <span itemProp="name">Top Tier Roofing</span>{" "}
          <span itemScope itemProp="aggregateRating" itemType="https://schema.org/AggregateRating">
            rated <span itemProp="ratingValue">{REVIEW_AVG}</span>/5 
            based on <span itemProp="reviewCount"  className="font-semibold text-base-content">{TOTAL_REVIEW_COUNT}</span> reviews.{" "}
          </span>
        </span> 
        </div>
      </div>}
    </div>
  );
};

export default TestimonialsAvatars;
