import React from "react";  
import PropTypes from "prop-types";
import Button from "../components/atoms/Button";
import { GatsbyImage, getImage } from "gatsby-plugin-image"; 
import { ArrowRight } from "lucide-react";
 
const CTA = ({ title, customTitle, customDesc, bgimage, serviceHeroImage, btn, props }) => {
  const CTABGImage = () => (
    <GatsbyImage
      className="object-cover object-center  aspect-video shadow-2xl " 
      image={getImage(bgimage ? bgimage : serviceHeroImage)}
      style={{
        left: "0",
        width: "100%",
        height: "100%",
        top: "0",
        zIndex: "1",
        position: "absolute"
      }}
      imgClassName="clip_path_1 clip_path_2_mobile "
      alt=""
    />
  );

  return (
    <div className="relative py-16 bg-neutral-900 mt-12 ">
       <div className=" relative h-[400px] -mt-12 md:mt-0  aspect-video  md:absolute  top-[0]  md:w-[calc(50%-16px)] md:h-[calc(100%)]">
 


 {bgimage || serviceHeroImage ? <CTABGImage /> : null}</div>
       <div className="max-w-7xl mx-auto  flex  overflow-hidden">
         
         <div className="hidden md:block w-1/2"/>
            
            <div className="relative w-full md:w-1/2 md:py-12 px-4 space-y-6  sm:py-12 sm:px-6 ">
              <h2 className="text-3xl font-bold text-white" id="join-heading">
                {customTitle ? customTitle : title}
              </h2>
              <p className="text-lg text-white">{customDesc && customDesc}</p>
              <Button
              size="big"
              color="red"
                  to="/contact/"
                title={"Get Started with " + title}
                >
                Get Started <ArrowRight size={16} className="ml-2"/>
              </Button>
            </div>
           
      </div>
    </div>
  );
};

CTA.propTypes = {
  title: PropTypes.string.isRequired,
  btn: PropTypes.string.isRequired,
};

export default CTA;
