
 import React, { useEffect, Suspense, useState, useCallback } from "react"; 
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"; 
import HeroRatingStars from "./hero-rating-stars";
import { Star } from "react-feather";
import Button from "./atoms/Button";
import useEmblaCarousel from 'embla-carousel-react'
import {
  DotButton,
  PrevButton,
  NextButton
} from './EmblaCarouselArrowsDotsButtons'
 

const Testimonials11 = (props) => (

  <StaticQuery
  query={graphql`
    query allContentfulTestimonialComp {
      allContentfulTestimonial(
        sort: { fields: date, order: DESC }
         limit: 20
      ) {
        edges {
          node {
            id
            title: reviewTitle 
            featuredReview
            date
            name: reviewer
            username: reviewer
            reviewImages {
              gatsbyImageData(width: 200)
            }
            userPhoto {
              gatsbyImageData(width: 65)
            }
            text: reviewBody {
              id
              reviewBody
            }
            category {
              id
            }
            source
          }
        }
      }
    }
  `}
  render={(data) => {
   

// The list of your testimonials. It needs 11 items to fill the grid. The last one (11th) is featured on large devices (span 2 columns + big font)
const list = data.allContentfulTestimonial.edges

 
// A single testimonial, to be rendered in  a list
const Testimonial = ({ i, item}) => {
  console.log("Imte",item && item.node)
  const testimonial = item && item.node || list[i] && list[i].node;

  if (!testimonial) return null;

  return (
    <div key={i}> 
      <figure className="relative h-full bg-white shadow-sm rounded-xl p-6">
      <div className={`flex items-center mb-2   `}>
          {new Array(5).fill("").map((item) => (
            <Star fill={'currentColor'}  className='text-brand-600' size={15} stroke={"0"} />
          ))}
        </div>
        <blockquote className="relative">
          <p className="text-sm text-neutral-content/80 line-clamp-6">{testimonial.text && testimonial.text.reviewBody}</p>
        </blockquote>
        <figcaption className="relative flex items-center justify-start gap-4 pt-4 mt-4 border-t border-neutral-content/5">
          <div className="overflow-hidden rounded-full bg-neutral-300 shrink-0">
            {testimonial.userPhoto ? (
              <GatsbyImage
              className="w-8 h-8 rounded-full object-cover"
              image={getImage(testimonial.userPhoto)}
              width={48}
              height={48}
              // style={{ minHeight: '100%', margin: '0 auto' }}
              
            />

            ) : (
              <span className="w-8 h-8 rounded-full flex justify-center items-center text-lg font-medium bg-neutral-300">
                {testimonial.name && testimonial.name.charAt(0)}
              </span>
            )}
          </div>
          <div className="w-full flex items-end justify-between gap-2">
            <div>
              <div className="text-sm font-medium text-neutral-content">
                {testimonial.name}
              </div>
             
            </div>

             
          </div>
          {/* {testimonial.source} */}
        </figcaption>
      </figure>
    </div>
  );
};
 
const [emblaRef, emblaApi] = useEmblaCarousel({slidesToScroll: 1, })
const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
const [selectedIndex, setSelectedIndex] = useState(0)
const [scrollSnaps, setScrollSnaps] = useState([])

const scrollPrev = useCallback(
  () => emblaApi && emblaApi.scrollPrev(),
  [emblaApi]
)
const scrollNext = useCallback(
  () => emblaApi && emblaApi.scrollNext(),
  [emblaApi]
)
const scrollTo = useCallback(
  (index) => emblaApi && emblaApi.scrollTo(index),
  [emblaApi]
)

const onInit = useCallback((emblaApi) => {
  setScrollSnaps(emblaApi.scrollSnapList())
}, [])


const onSelect = useCallback((emblaApi) => {
  setSelectedIndex(emblaApi.selectedScrollSnap())
  setPrevBtnDisabled(!emblaApi.canScrollPrev())
  setNextBtnDisabled(!emblaApi.canScrollNext())
}, [])

useEffect(() => {
  if (!emblaApi) return

  onInit(emblaApi)
  onSelect(emblaApi)
  emblaApi.on('reInit', onInit)
  emblaApi.on('reInit', onSelect)
  emblaApi.on('select', onSelect)
}, [emblaApi, onInit, onSelect])


    return (
     
    <>
      <div className="max-w-7xl mx-auto">
      <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        {list.map((item, index) => (
           <div className="md:flex-[0_0_33%] embla__slide rounded-lg shadow-sm bg-white mx-4">
            <Testimonial item={item} key={index} i={index} />
             </div>
        ))}
        
      </div>
      <div className="embla__buttons p-4 space-x-2">
          <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} className='bg-white shadow p-3 text-gray-700  rounded-full h-8 w-8' />
          <NextButton onClick={scrollNext} disabled={nextBtnDisabled}  className='bg-white shadow p-3 text-gray-700 rounded-full h-8 w-8'/>
        </div>
    </div>
        {/* <div className="flex flex-col text-center w-full mb-8">
        
          <h2 className="text-3xl md:text-4xl font-display text-neutral-800 mb-2 font-extrabold text-center max-w-7xl mx-auto text-balance ">
        
              Hundreds of <span className="text-brand-600">Happy Customers</span> 
            </h2>
            <p className="lg:w-2/3 mx-auto leading-relaxed  text-neutral-600 text-center max-w-4xl mx-auto py-2 text-base md:text-lg">
          Real reviews from real customers - see what they have to say about their experience with us.
          </p>
          <div className="border-t border-b py-4 mx-auto max-w-2xl mt-4">
          <HeroRatingStars theme={'dark'} centerAll classes="text-center   justify-center" />
          </div>
         
        
        </div> */}

         
      </div>
    
 </>
    )
  }}
/> 
);

export default Testimonials11;

 