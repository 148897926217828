import React, { useRef, useState } from "react";
import Button from "./atoms/Button";
import { ArrowRight, Play, PlayCircle } from "lucide-react";
import OurProcessVideo from "./OurProcessVideo";

const process = [
  {
    stepNumber: 1,
    title: "Initial Consultation and Comprehensive Inspection",
    description:
      "The process starts with an in-depth phone consultation to understand your needs, followed by a thorough inspection of your property to assess the roof's condition and identify any issues.",
  },
  {
    stepNumber: 2,
    title: "Customized Solution and Planning",
    description:
      "Based on the inspection, we present tailored roofing options and discuss financing or insurance processes. We ensure the chosen plan aligns with your requirements, preferences, and budget.",
  },
  {
    stepNumber: 3,
    title: "Preparation and Installation",
    description:
      "We prepare the site to protect your property and then proceed with the roofing project. This includes removing old materials, repairing the foundation if necessary, and installing high-quality roofing materials for a durable, leak-free system.",
  },
  {
    stepNumber: 4,
    title: "Final Inspection and Site Cleanup",
    description:
      "After the installation, we conduct a final quality check to ensure the integrity and performance of your new roof. The project concludes with a thorough cleanup of the site, ensuring your property is left in perfect condition.",
  },

  // {
  //   stepNumber: 1,
  //   title: "Initial Consultation and Inspection",
  //   description: "The process begins with an initial phone call to understand your roofing concerns, followed by a thorough on-site inspection to assess the condition of your roof and identify any additional issues."
  // },
  // {
  //   stepNumber: 2,
  //   title: "Reviewing Options and Planning",
  //   description: "Based on the inspection, we present various roofing options, including repair, replacement, and material choices. We discuss financing for cash jobs or guide you through the insurance process, ensuring a plan that fits your needs and budget."
  // },
  // {
  //   stepNumber: 3,
  //   title: "Preparing the Site and Foundation",
  //   description: "Prior to any work, we take measures to protect your property, covering vulnerable areas. The roofing project starts with removing old materials down to the decking, replacing any damaged wood to ensure a solid foundation for the new roof."
  // },
  // {
  //   stepNumber: 4,
  //   title: "Installation of Quality Roofing Materials",
  //   description: "Using only the highest quality materials, we install a complete GAF roofing system, incorporating synthetic felt and ice and water barriers in critical areas to create a durable, leak-free roof."
  // },
  // {
  //   stepNumber: 5,
  //   title: "Final Inspection and Cleanup",
  //   description: "After installation, we conduct a final inspection to ensure the quality of our workmanship and the integrity of the roofing system. We then clean up the site, ensuring your property is left in pristine condition."
  // }
];

const Item = ({ item }) => {
  return (
    <li className="relative flex gap-x-3 sm:gap-x-6">
      <div className="flex w-6 flex-none flex-col items-center gap-y-4">
        <span
          className="inline-flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-gray-900 text-2xs font-semibold leading-6 text-white"
          aria-hidden="true"
        >
          {item.stepNumber}
        </span>
        <div className="hidden w-px grow bg-gray-400/10 sm:block"></div>
      </div>

      <div className="flex-auto">
        <p className="font-display font-semibold text-lg md:text-lg mb-3 text-gray-800">{item?.title}</p>

        <div className="leading-relaxed text-gray-600 font-display text-sm md:text-base max-w-3xl pb-4">
          {item?.description}
        </div>
      </div>
    </li>
  );
};

const ProcessSection = ({ title, classnames }) => {
  return (
    <div className={`relative py-16 bg-gradient-to-r from-white to-brand-50 overflow-hidden ${classnames}`}>
      <div className="max-w-7xl mx-auto  flex  justify-center">
        <div className="relative w-full max-w-7xl  md:py-12 px-4 space-y-8  sm:py-12 sm:px-6 ">
          <p className="uppercase text-brand-600 font-semibold tracking-wide text-sm -mb-4">Our Process</p>
          <h2 className=" text-3xl md:text-4xl font-display text-nuetral-900 font-bold">
            Transforming Your Roof: Our Process Explained
          </h2>
          <div className="flex flex-wrap md:items-center">
            <div className="md:order-1 order-2 w-full md:w-1/2">
              <ul className="grid grid-cols-1 gap-6 mt-2">
                {process.map((item, i) => (
                  <Item key={i} item={item} />
                ))}
              </ul>
            </div>
            <div className="order-1  w-full md:w-1/2 md:pl-16">
              <OurProcessVideo />
              <div className="font-display p-8 text-center flex">
                <div>
                  <div className="flex items-center mb-2 mx-auto justify-center space-x-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-5 h-5 text-brand-600"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm6.39-2.908a.75.75 0 0 1 .766.027l3.5 2.25a.75.75 0 0 1 0 1.262l-3.5 2.25A.75.75 0 0 1 8 12.25v-4.5a.75.75 0 0 1 .39-.658Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <p className="uppercase text-brand-600 font-semibold tracking-wide text-sm mt-1 ">Video</p>
                  </div>
                  <p className="text-gray-400 font-normal">
                    From initial consultation to the final touches, we take you behind the scenes to show how Top Tier
                    Roofing ensures excellence and satisfaction at every step.{" "}
                  </p>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessSection;
